import "./Timer.css";

import React, { useEffect, useState } from "react";

import { ReactComponent as Clock } from "assets/clock-regular.svg";
import PropTypes from "prop-types";

const Timer = ({ seconds }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(Number(seconds));
  const [start, setStart] = useState(false);

  useEffect(
    () => {
      if (!start) {
        return;
      }
      let timer1 = setTimeout(() => {
        setRemainingSeconds(remainingSeconds - 1);
      }, 1000);
      if (remainingSeconds === 0) {
        clearTimeout(timer1);
      }
      // this will clear Timeout when component unmont like in willComponentUnmount
      return () => {
        clearTimeout(timer1);
      };
    },
    [start, remainingSeconds]
    //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  );

  if (!start) {
    return (
      <button aria-label="Start timer" className="btn btn-primary" onClick={() => setStart(true)}>
        <Clock style={{ color: "#037fca" }} width="20" />
      </button>
    );
  }

  return (
    <span className={`timer ${remainingSeconds === 0 ? "timer-over wiggle" : ""}`}>
      {remainingSeconds}
    </span>
  );
};

Timer.propTypes = {
  seconds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default Timer;
