import React, { createContext, useEffect, useState } from "react";

export const SettingsContext = createContext();

export const cards = {
  es: import("assets/cards/es-ar.json"),
  "es-ar": import("assets/cards/es-ar.json"),
  "en-us": import("assets/cards/en-us.json"),
  "ru-ru": import("assets/cards/ru-ru.json")
};

const mapLangToRegionalLang = {
  en: "en-us",
  es: "es-ar",
  ru: "ru-ru"
};

const defaultLanguage = () => {
  const storedSettings = JSON.parse(window.localStorage.getItem("settings"));
  if (storedSettings && storedSettings.language) {
    return storedSettings.language;
  }
  const browserLang =
    window.navigator.userLanguage || window.navigator.language;

  const regionalLang = Object.keys(cards).find(
    lang => lang === browserLang.toLowerCase()
  );
  if (regionalLang) {
    return regionalLang;
  }
  const lang = Object.keys(cards).find(
    lang => lang === browserLang.toLowerCase().split("-")[0]
  );
  if (lang) {
    return mapLangToRegionalLang[lang];
  }
  return "en-us";
};

export const SettingsProvider = ({ children }) => {
  const storedSettings =
    JSON.parse(window.localStorage.getItem("settings")) || {};

  const defaultSettings = {
    language: defaultLanguage(),
    cards: cards,
    deck: cards[defaultLanguage()],
    drink: !!storedSettings.drink,
    hardcore: !!storedSettings.hardcore,
    numberOfCards: parseInt(storedSettings.numberOfCards) || 15,
    showBlinkingMessage:
      typeof storedSettings.showBlinkingMessage === "boolean"
        ? storedSettings.showBlinkingMessage
        : true
  };

  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    window.localStorage.setItem(
      "settings",
      JSON.stringify({
        language: settings.language,
        drink: settings.drink,
        hardcore: settings.hardcore,
        numberOfCards: settings.numberOfCards,
        showBlinkingMessage: settings.showBlinkingMessage
      })
    );
  }, [settings]);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {children}
    </SettingsContext.Provider>
  );
};
