import "./Container.css";

import React from "react";

const Container = ({ children }) => (
  <div className="container">
    <div className="container__block">{children}</div>
  </div>
);

export default Container;
