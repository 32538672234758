import "./deck.css";

import React, { useState } from "react";
import { animated, interpolate, useSprings } from "react-spring";

import { ReactComponent as Beer } from "assets/beer-solid.svg";
import Dice from "components/Dice";
import { ReactComponent as Fire } from "assets/fire-solid.svg";
import PropTypes from "prop-types";
import Timer from "components/Timer";
import { useGesture } from "react-use-gesture";

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = i => ({
  x: 0,
  y: i * -0.3,
  scale: 1,
  rot: -6 + Math.random() * 10,
  delay: i * 10
});
const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

function Deck({ cards, onGameOver }) {
  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, set] = useSprings(cards.length, i => ({
    ...to(i),
    from: from(i)
  })); // Create a bunch of springs using the helpers above

  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useGesture(
    ({
      args: [index],
      down,
      delta: [xDelta],
      distance,
      direction: [xDir],
      velocity
    }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      set(i => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);

        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0; // When a card is gone it flys out left or right, otherwise goes back to zero

        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          pepo: 12,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 }
        };
      });

      if (!down && gone.size === cards.length)
        setTimeout(() => {
          gone.clear() ||
            // set(i => to(i)) &&
            onGameOver();
        }, 600);
    }
  );
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return props.map(({ x, y, rot, scale }, i) => (
    <animated.div
      className="card"
      key={i}
      style={{
        transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`)
      }}
    >
      {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
      <animated.div
        className="card__content"
        {...bind(i)}
        style={{
          transform: interpolate([rot, scale], trans),
          padding: "2rem",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <h1>{cards[i].title}</h1>
        <p className="card__description">{cards[i].description}</p>
        {!!cards[i].timer && (
          <p>
            <Timer key={i} seconds={cards[i].timer || 10} />
          </p>
        )}
        {!!cards[i].dice && (
          <p>
            <Dice faces={cards[i].dice.split(",")} />
          </p>
        )}
        <p>
          {cards[i].tags.includes("drink") && (
            <span title="drinking card">
              <Beer style={{ color: "#caa903" }} width="20" />
            </span>
          )}
          {cards[i].tags.includes("hardcore") && (
            <span title="hardcore card">
              <Fire style={{ color: "#ca0303" }} width="20" />
            </span>
          )}
        </p>
        <p>
          <i>{cards[i].quote}</i>
        </p>
      </animated.div>
    </animated.div>
  ));
}

Deck.propTypes = {
  onGameOver: PropTypes.func
};

Deck.defaultProps = {
  onGameOver: () => {}
};

export default Deck;
