import Container from "components/Container";
import Footer from "components/Footer";
import { Link } from "@reach/router";
import React from "react";

const Privacy = () => {
  return (
    <Container >
      <div style={{ marginBottom: "15px" }}>
        <Link to="/">Back</Link>
      </div>
      <h2 style={{textAlign: "center"}}>Privacy</h2>
      <p>
        At the moment nothing is tracked and no cookies are set.
        If you find something weird contact me.
      </p>
      <Footer>

      </Footer>
    </Container>
  );
};

export default Privacy;
