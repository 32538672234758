import Container from "components/Container";
import Footer from "components/Footer";
import { Link } from "@reach/router";
import React from "react";

const Rules = () => {
  return (
    <Container>
      <div style={{ marginBottom: "15px" }}>
        <Link to="/">Back</Link>
      </div>
      <h2 style={{ textAlign: "center" }}>Rules</h2>
      <ul>
        <li>The objective of the game is to win.</li>
        <li>
          If you lose, then you don't win, and you are eliminated for this
          round.
        </li>
        <li>Yu can choose to move the phone around or just one person swapping the cards</li>
        <li>If everybody else besides you loses, then you win</li>
        <li>The cards with a timer allow you to find and play the song before starting it.</li>
        <li>
          If the cards are over, a new deck will be provided and the game can
          continue normally.
        </li>
        <li>
          If a round is longer than 4 minutes, something weird is going on
        </li>
        <li>
          If the rules are not clear, the funniest solution is the correct one
        </li>
        <li>
          You can add your own rules to the game, like if someone loses, they
          must drink
        </li>
      </ul>
      <Footer></Footer>
    </Container>
  );
};

export default Rules;
