import "./Dice.css"

import React, { useState } from "react";

import { ReactComponent as DiceIcon } from "assets/dice-d6-solid.svg";
import PropTypes from 'prop-types';

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const Dice = ({faces}) => {
  const [start, setStart] = useState(false);
  if (!start) {
    return (
      <button aria-label="Roll dice" className="btn btn-primary" onClick={() => setStart(true)}>
        <DiceIcon style={{ color: "#037fca" }} width="20" />
      </button>
    );
  }
  return (
    <span className="dice">
      {shuffle(faces)[0]}
    </span>
  );
};

Dice.propTypes = {
  faces: PropTypes.array,
};

Dice.defaultProps = {
  faces: [],
};

export default Dice;