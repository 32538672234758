import "./TopBar.css";

import React, { useContext } from "react";

import { ReactComponent as Bars } from "assets/bars-solid.svg";
import InstallPWA from "components/InstallPWA";
import { Link } from "@reach/router";
import { SettingsContext } from "contexts/SettingsContext";

const TopBar = () => {
  const [settings] = useContext(SettingsContext);
  return (
    <div className="top-bar">
      <span>
        <Link to="menu">
          <Bars width="20" />
        </Link>
        <InstallPWA />
      </span>
      <span className="logo">VECK.APP</span>

      <span className="lang">{settings.language}</span>
    </div>
  );
};

export default TopBar;
