import React, { useContext, useEffect, useState } from "react";

import Deck from "components/deck";
import { SettingsContext } from "contexts/SettingsContext";
import TopBar from "components/TopBar";

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const Home = () => {
  const [settings] = useContext(SettingsContext);
  const [cards, setCards] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [cardPosition, setCardPosition] = useState(0);
  useEffect(() => {
    settings.deck.then(cards => {
      setCards(
        shuffle(
          cards.default.filter(
            card =>
              card.tags.includes("normal") ||
              (settings.drink && card.tags.includes("drink")) ||
              (settings.hardcore && card.tags.includes("hardcore"))
          )
        )
      );
    });
  }, [settings]);

  // useEffect(
  //   () => {
  //     if (settings.showBlinkingMessage === false) {
  //       return
  //     }

  //     let timer1 = setTimeout(
  //       () =>
  //         setSettings(prevSettings => ({
  //           ...prevSettings,
  //           showBlinkingMessage: false
  //         })),
  //       3000
  //     );

  //     // this will clear Timeout when component unmont like in willComponentUnmount
  //     return () => {
  //       clearTimeout(timer1);
  //     };
  //   },
  //   [] //useEffect will run only one time
  //   //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  // );

  const onGameOver = () => {
    if (cardPosition + settings.numberOfCards >= cards.length) {
      console.log("Restarting deck...");
      setCardPosition(0);
      setCards(shuffle(cards));
      setKey(0);
    } else {
      setCardPosition(cardPosition + settings.numberOfCards);
      setKey(cardPosition + settings.numberOfCards); // Key is used to re-render the cards
    }
  };

  return (
    <>
      <TopBar />
      <Deck
        key={key}
        onGameOver={onGameOver}
        cards={shuffle(
          cards.slice(cardPosition, settings.numberOfCards + cardPosition)
        )}
      />
      {/* {settings.showBlinkingMessage && (
        <Footer>
          <BlinkingMessage>SWIPE</BlinkingMessage>
        </Footer>
      )} */}
    </>
  );
};

export default Home;
