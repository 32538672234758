import React, { useContext, useState } from "react";

import { ReactComponent as Beer } from "assets/beer-solid.svg";
import { ReactComponent as Fire } from "assets/fire-solid.svg";
import FormGroup from "components/FormGroup";
import { Redirect } from "@reach/router";
import { SettingsContext } from "contexts/SettingsContext";
import useForm from "react-hook-form";

const Settings = () => {
  const [settings, setSettings] = useContext(SettingsContext);
  const [redirect, setRedirect] = useState(false);
  const { register, handleSubmit } = useForm({ defaultValues: settings });

  if (redirect) {
    return <Redirect to="/" noThrow />;
  }

  const onSubmit = data => {
    setSettings({
      ...settings,
      ...data,
      deck: settings.cards[data.language],
      numberOfCards: parseInt(data.numberOfCards)
    });
    setRedirect(true);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <label htmlFor="language" className="label">
          Cards language
        </label>
        <select name="language" id="language" className="input" ref={register}>
          <option value="en-us">English (US)</option>
          <option value="es-ar">Spanish (AR)</option>
          <option value="ru-ru">Russian (RU)</option>
        </select>
      </FormGroup>
      <FormGroup>
        <label htmlFor="numberOfCards" className="label">
          Number of cards per deck (less cards improves performance)
        </label>
        <input type="number" name="numberOfCards" id="numberOfCards" className="input" ref={register} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="drink" className="label-for">
          <input
            type="checkbox"
            name="drink"
            className="checkbox"
            id="drink"
            ref={register}
          />
          Drink cards <Beer width="15" style={{verticalAlign: "middle"}} />
        </label>
      </FormGroup>
      <FormGroup>
        <label htmlFor="hardcore" className="label-for">
          <input
            type="checkbox"
            className="checkbox"
            name="hardcore"
            id="hardcore"
            ref={register}
          />
          Hot cards <Fire width="15" style={{verticalAlign: "middle"}} />
        </label>
      </FormGroup>
      <FormGroup>
        <input type="submit" value="Save" aria-label="Save settings" className="btn btn-primary" />
      </FormGroup>
    </form>
  );
};

export default Settings;
