import "./App.css";

import Home from "pages";
import Menu from "pages/Menu";
import Privacy from "pages/Privacy";
import React from "react";
import { Router } from "@reach/router";
import Rules from "pages/Rules";
import { SettingsProvider } from "contexts/SettingsContext";

const App = () => (
  <div>
    <SettingsProvider>
      <Router>
        <Home path="/" />
        <Menu path="menu" />
        <Rules path="rules" />
        <Privacy path="privacy" />
      </Router>
    </SettingsProvider>
  </div>
);

export default App;
