import Container from "components/Container";
import { Link } from "@reach/router";
import NavItem from "components/NavItem";
import React from "react";
import Settings from "components/Settings";

const Menu = () => {
  return (
    <Container>
      <div style={{ marginBottom: "15px" }}>
        <Link to="/">Back</Link>
      </div>

      <Settings />
      <hr />
      <nav>
        <NavItem>
          <Link to="/rules">Rules</Link>
        </NavItem>
        <NavItem>
          <Link to="/privacy">Privacy Policy and Cookies</Link>
        </NavItem>
      </nav>
      <hr />
      <div>
        You can help me by following me and sharing my future projects.
        You can also contact me for feedback or issues.
        <NavItem>
          <a
            href="https://twitter.com/santiwilly"
            target="_blank"
            rel="noopener noreferrer"
          >
            twitter: @santiwilly
          </a>
        </NavItem>
        <NavItem>
          <a
            href="https://www.instagram.com/woile/"
            target="_blank"
            rel="noopener noreferrer"
          >
            insta: @woile
          </a>
        </NavItem>
        <NavItem>
          version {process.env.REACT_APP_VERSION}
        </NavItem>
      </div>
      <hr />

      <p>
        <small>
          Thanks to fontawesome for the icons which have not been modified.{" "}
          <a href="https://fontawesome.com/license">LICENSE</a>
        </small>
      </p>
    </Container>
  );
};

export default Menu;
